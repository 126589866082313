import React, { useEffect } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

const common = `
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
	<meta name="theme-color" content="#000000">
	<link rel="manifest" href="%PUBLIC_URL%/manifest.json">
	<link rel="shortcut icon" href="%PUBLIC_URL%/favicon.ico">
`;

function Home(props) {
  useEffect(() => {
    document.head.innerHTML = `
    ${common}
    <title>Home Title</title>
    <meta name="description" content="This is the home">
    <meta name="age-meta-label" content="age=18" />
  `;
  }, []);
  return <h3>This is Home</h3>;
}

function Blog(props) {
  useEffect(() => {
    document.head.innerHTML = `
    ${common}
    <title>Blog Title</title>
    <meta name="description" content="This is the blog">
    <meta name="age-meta-label" content="age=18" />
  `;
  }, []);

  const { push } = props.history;
  return (
    <>
      <h3>My blog nobody reads</h3>
      <button onClick={() => push("/")}>Back Home</button>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <header>
        <BrowserRouter>
          <Link to="/"> Home </Link>
          <br />
          <Link to="/blog"> Blog </Link>

          <Route exact path="/" component={Home} />
          <Route
            path="/blog"
            component={Blog}
          />
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
